
/* Calendar */
.card {
  &.with-calendar,
  &.with-emails {
    &.dashboard {
      height: calc(100% - 3.7rem);
    }
  }

  &.with-calendar {
    &.dashboard {
      .rbc-time-content {
        max-height: 70rem;
      }
    }
  }

  &.with-emails {
    &.dashboard {
      .card-body {
        padding: 0;

        .lp-emails-filter {
          padding: 0.8rem 1.6rem;
        }

        .lp-emails-list {
          max-height: 73.8rem;
          overflow-y: auto;
        }

        .lp-emails-pagination {
          margin: 0;
          padding: 0.8rem 1.6rem;
        }
      }
    }
  }
}

.lp-errors {
  + .card {
    &.with-calendar,
    &.with-emails {
      &.dashboard {
        height: auto;
      }
    }
  }
}

/* Lists */
.card {
  &.with-list {
    &.dashboard-list {
      display: flex;
      height: calc(100% - 3.7rem);

      .inner-content {
        display: table;
        height: auto;
        width: 100%;
    
        .card-header {
          display: table-row;
          width: 100%;
    
          > div {
            display: table-cell;
            padding: 1.4rem 0.8rem;
    
            &:first-child {
              padding-left: 1.6rem;
            }
    
            &:last-child {
              padding-right: 1.6rem;
            }
          }
        }
    
        .card-body {
          display: table-row-group;
          width: 100%;
    
          .lp-list-row {
            display: table-row;
            width: 100%;
    
            > div {
              border-top: 0.1rem solid var(--lp-secondary-200);
              display: table-cell;
              line-height: 2.8rem;
              padding: 0.8rem;
              vertical-align: middle;
              width: auto;
    
              &:first-child {
                padding-left: 1.6rem;
              }
      
              &:last-child {
                padding-right: 1.6rem;
              }
    
              &.lp-list-action {
                padding-top: 0.4rem;
                padding-bottom: 0.4rem;
                width: 5.6rem;
              }
            }
          }
        }
      }
    }

    &.empty-list {
      height: auto;
    }
  }
}
