/* NavBar */
.lp-navbar {
  background-color: var(--lp-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .delegate & {
    background-color: var(--lp-primary-alt);
  }
}

/* Logo */
.lp-logo {
  background-color: rgba(var(--lp-white-rgb), 0.2);
  color: var(--lp-light);
  font-size: 2.4rem;
  font-weight: 700;
  overflow: hidden;
  padding: 0.8rem 0.4rem;
  text-align: center;

  img {
    height: 5.8rem;
    vertical-align: initial;
  }
}

/* Main Menu */
.lp-main-menu {
  flex: 1 1 100%;
  overflow-y: overlay;

  @-moz-document url-prefix() {
    overflow-y: auto;
  }
}

.lp-main-menu-item {
  margin-bottom: 0.8rem;

  > a,
  > .lp-main-menu-item-parent {
    align-items: center;
    color: var(--lp-light);
    display: flex;
    flex-direction: row;
    padding: 1.2rem 2.3rem;
    position: relative;

    &:hover {
      background-color: var(--lp-primary-850);
      color: var(--lp-light);

      .delegate & {
        background-color: var(--lp-primary-alt-850);
      }
    }

    &:before {
      background-color: var(--lp-light);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 0.7rem;
    }

    &.active {
      background-color: var(--lp-primary-850);

      .delegate & {
        background-color: var(--lp-primary-alt-850);
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .lp-main-menu-item-icon {
    align-items: center;
    display: flex;
    font-size: 2.4rem;
    justify-content: center;
  }

  .lp-main-menu-item-arrow {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: auto;

    svg {
      font-size: 2.4rem;
    }
  }
}

/* Main Menu Submenu */
.lp-main-menu-submenu {
  background-color: var(--lp-primary-700);

  .delegate & {
    background-color: var(--lp-primary-alt-700);
  }

  .lp-main-menu-submenu-title {
    background: var(--lp-primary-850);
    border-radius: 0 0.8rem 0 0;
    color: var(--lp-white);
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0.4rem 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;

    .delegate & {
      background: var(--lp-primary-alt-850);
    }
  }

  .lp-main-menu-submenu-item {
    > a {
      align-items: center;
      color: var(--lp-white);
      display: flex;
      flex-direction: row;
      font-size: 1.4rem;
      padding: 0.8rem 1.6rem;
      position: relative;
  
      &:hover,
      &.active {
        background-color: var(--lp-primary-550);
        color: var(--lp-white);

        .delegate & {
          background-color: var(--lp-primary-alt-550);
          color: var(--lp-text);
        }
      }
    }

    .lp-main-menu-submenu-item-text {
      white-space: nowrap;
    }
  }
}

.lp-navbar {
  &.expanded {
    .lp-main-menu-item-parent {
      &.opened {
        background-color: var(--lp-primary-850);
        color: var(--lp-light);

        .delegate & {
          background-color: var(--lp-primary-alt-850);
        }
      }
    }

    .lp-main-menu-submenu {
      display: none;

      &.show {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.collapsed {
    .lp-main-menu-submenu {
      border-radius: 0 0.8rem 0.8rem 0;
      box-shadow: 0.5rem 0 1rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
      display: flex;
      flex-direction: column;
      left: 100%;
      opacity: 0;
      position: absolute;
      visibility: hidden;

      .lp-main-menu-submenu-item {
        &:last-child {
          > a {
            border-radius: 0 0 0.8rem 0;
          }
        }
      }
    }

    .lp-main-menu-item {
      &:hover {
        .lp-main-menu-submenu {
          opacity: 1;
          visibility: visible;

          + .lp-main-menu-item-parent {
            background-color: var(--lp-primary-850);
            color: var(--lp-light);

            .delegate & {
              background-color: var(--lp-primary-alt-850);
            }
          }
        }
      }
    }
  }
}

/* NavBar Toggle */
.lp-navbar-toggle {
  align-items: center;
  background-color: var(--lp-primary);
  border: 0.1rem solid var(--lp-primary-850);
  border-radius: 2rem;
  bottom: 7rem;
  color: var(--lp-light);
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 2.4rem;
  justify-content: center;
  left: calc(100% - 1.2rem);
  position: absolute;
  width: 2.4rem;

  .delegate & {
    background-color: var(--lp-primary-alt);
  }
}

/* Mobile Trigger */
.lp-mobile-trigger {
  align-items: center;
  background-color: var(--lp-primary);
  border-radius: 0.4rem;
  color: var(--lp-light);
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  height: 3.6rem;
  justify-content: center;
  left: 2.4rem;
  position: fixed;
  top: 2.4rem;
  transition: 0.4s ease-in-out;
  width: 3.6rem;

  .delegate & {
    background-color: var(--lp-primary-alt);
  }

  .mobile-opened & {
    background-color: var(--lp-light);
    color: var(--lp-primary);
    left: 28.3rem;
  }
}

/* Mobile Overlay */
.lp-mobile-overlay {
  background-color: rgba(var(--lp-black-rgb), 0.75);
  display: block;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: 0.4s ease-in-out;
  visibility: hidden;
  width: 100vw;
  z-index: 950;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
