/* Tooltip Icon */
.lp-tooltip-icon {
  display: inline-flex;
  font-size: 2rem;
  vertical-align: text-bottom;

  &.left {
    margin-right: 0.4rem;
  }

  &.right {
    margin-left: 0.4rem;
  }

  &.info {
    svg {
      color: var(--lp-info);
      fill: var(--lp-info);
    }
  }

  &.warning {
    svg {
      color: var(--lp-warning);
      fill: var(--lp-warning);
    }
  }

  &.error {
    svg {
      color: var(--lp-danger);
      fill: var(--lp-danger);
    }
  }

  &.linked {
    svg {
      color: var(--lp-primary-alt);
    }
  }
}
